/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Container, Box, Text, Flex, Link } from 'theme-ui';
import SplitLogoDark from 'assets/images/split-logo-dark.png';

const Footer = () => {
  return (
    <Box as="footer" sx={styles.footer}>
      <Container>
        <hr sx={styles.hr}/>
        <Box sx={styles.blockTitle}>
          
          <Flex sx={styles.flexContainer}>
            <img 
              // width="25"
              height="30"
              src={SplitLogoDark} 
              sx={{ marginRight: '10px' }} 
              alt="SPLIT Logo"
            />
            <Text as="p">
            by{' '}
              <Link 
                href="https://funble.kr/" 
                target="_blank" 
                rel="noopener noreferrer" 
                sx={styles.link}>
                  FUNBLE
              </Link>

            </Text>
          </Flex>
          <Text as="p">
            <br/>
            ©️ {new Date().getFullYear()} <Link 
                href="https://funble.kr/" 
                target="_blank" 
                rel="noopener noreferrer" 
                sx={styles.link}>
                  FUNBLE Inc.
              </Link> <br/>
            ALL RIGHTS RESERVED <br/>
            <Link 
                href="mailto:split@funble.kr" 
                target="_blank" 
                rel="noopener noreferrer" 
                sx={styles.link}>
                  split@funble.kr
              </Link>
            <br/>
            <br/>
            SPLIT, powered by Funble, revolutionizes access to capital markets with its leading RWA tokenization platform. Offering licensed, regulated, and innovative solutions, SPLIT empowers asset owners, companies and investors in primary raises, recapitalizations, and secondary trading.<br/><br/>
            Funble, operating with an exclusive Regulatory Sandbox license from the Korea Financial Services Commission ("FSC"), leads the security token market with its platform for issuing and trading security tokens. 

          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

const styles = {
  hr: {
    mt: 0,  
    mb: 3,  
    borderColor: '#b4b4b4', 
    borderWidth: '0.1px', 
    borderStyle: 'solid', 
  },
  blockTitle: {
    textAlign: 'left',
    h2: {
      fontSize: ['24px', null, '28px', '30px'],
      lineHeight: [1.35],
      color: 'heading',
      fontWeight: 'body',
    },
    p: {
      fontSize: ['15px', null, '16px'],
      fontWeight: 'semibold',
      lineHeight: 1.85,
      color: 'white',
    },
  },
  footer: {
    backgroundColor: 'heading_secondary',
    pb: ['25px'],
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'left', 
    justifyContent: 'left', 
    mt: 2,
  },
  link: {
    color: 'inherit', 
    textDecoration: 'none', 
    '&:hover': {
      color: '#24a0a4', 
    },
    '&:active': {
      color: 'inherit', 
    },
  },
  split: {
    color: '#0080ff', 
  },
  funble: {
    color: '#24a0a4', 
  },
};